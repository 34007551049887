<template>
  <el-dialog
    title="搜索用户 / 群组"
    :visible.sync="dialogVisible"
    center
    width="1000px"
    :before-close="handleClose">
    <el-form :inline="true" :model="searchForm">
      <el-form-item label="搜索">
        <el-input v-model="searchForm.keyword" placeholder="请输入用户ID / 群名搜索">
          <el-select v-model="searchForm.type" slot="prepend" placeholder="请选择" style="width: 100px">
            <el-option label="用户" value="private">用户</el-option>
            <el-option label="群组" value="group"></el-option>
          </el-select>
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleSearch">查询</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="datas"
      border>
      <el-table-column
        type="index"
        label="序号"
        width="80">
      </el-table-column>
      <el-table-column
        prop="name"
        label="昵称 / 群名">
      </el-table-column>
      <el-table-column
        prop="line_id"
        label="代理线">
      </el-table-column>
      <el-table-column
        width="120"
        label="操作">
        <template slot-scope="scope">
          <el-button-group>
            <el-button type="primary" size="mini" @click="handleNewConversation(scope.row)">发起会话</el-button>
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>

    <div slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { requestHttp } from "@/api/main";
import {
  YeIMUniSDK,
  YeIMUniSDKDefines
} from '@/utils/wzJun1-YeIM-Uni-SDK/js_sdk/yeim-uni-sdk.min';

export default {
  name: "DialogOrderLog",
  data() {
    return {
      dialogVisible: false,
      userId: 0,
      datas: [],
      searchForm: {
        keyword: '',
        type: 'private'
      }
    }
  },
  methods: {
    toggle(type) {
      this.searchForm.type = type
      this.dialogVisible = true
    },
    handleClose() {
      this.dialogVisible = false
    },
    handleSearch() {
      this.fetchData()
    },
    fetchData() {
      const that = this
      requestHttp('agent/search_user', this.searchForm).then((res) => {
        if (res.code == 200) {
          that.datas = res.data
        } else {
          that.$message.error(res.message)
        }
      })
    },
    handleNewConversation(item) {
      const that = this
      requestHttp('agent/new_conversation', {
        'type': item.type,
        'to_id': item.to_id
      }).then((res) => {
        if (res.code == 200) {
          // 重新加载好友等数据
          that.$store.state.conversationList = []
          that.$store.state.friendList = []
          that.$store.state.friendApplyUnread = []
          that.$store.state.friendApplyList = []

          YeIMUniSDK.getInstance().reload();

          YeIMUniSDK.getInstance().getGroupList({
            success: (res) => {
              that.$store.state.groupList = res.data;
            },
            fail: (err) => {
            }
          });

          that.$emit('new-conversation', item)
          that.handleClose()
        } else {
          that.$message.error(res.message)
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
