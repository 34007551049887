<template>
  <el-container id="app-menu">
    <el-aside
      theme="light"
      class="layout-sider"
      width="230px"
    >
      <div style="background: #FAFAFA; padding: 10px;">
        <div v-if="!connected" style="color: red; font-weight: bold">
          连接中....
        </div>
        <div style="display: flex; justify-content: space-between;">
          <div style="width: 160px;">
            <el-input
              v-model.trim="keyword"
              placeholder="搜索"
              suffix-icon="el-icon-search"
              @keyup="onSearch"
              @search="onSearch"
            />
          </div>
          <div style="width: 38px; line-height: 38px; background: #409EFF; border-radius: 4px;">
            <el-popover
              trigger="hover"
            >
              <el-button @click="handleShowSearch('private')">用户</el-button>
              <el-button @click="handleShowSearch('group')">群组</el-button>
              <el-icon slot="reference" class="el-icon-plus" style="color: #fff"/>
            </el-popover>
          </div>
        </div>
      </div>
      <el-tabs class="el-tabs el-tabs2" v-model="activeTabName" :stretch="true" @tab-click="handleTabClick">
        <el-tab-pane :label="`会话 ` + conversationList.length + ``" name="first">
          <vue-scroll style="height: calc(100vh - 125px)" :ops="ops" @handle-scroll="handleScroll" v-if="conversationList.length>0">
            <div v-for="(item, index) in conversationList" :key="index">
              <div class="chat-item"
                :class="{active: current.conversationId == item.conversationId}"
              >
                <div :class="{avatar: true, withdraw: item.has_withdraw > 0, 'withdraw-virtual': item.withdraw_virtual > 0, 'withdraw-submit': item.withdraw_submit > 0, 'withdraw-freeze': item.withdraw_status == 2}"
                  v-if="item.type === 'private'"
                  @click="selectUser(item, index)"
                  @contextmenu.prevent="(e) => { onContextmenu(e, item) }"
                >
                  <el-badge v-if="item.unread > 0" :value="item.unread">
                    <el-image :src="thumb(item.userInfo.thumb_avatar)"/>
                  </el-badge>
                  <el-image v-else :src="thumb(item.userInfo.thumb_avatar)"/>
                  <div class="status" :class="{off: !item.userInfo.online || item.userInfo.online == 0}"></div>
                </div>
                <div class="avatar"
                  v-else
                  @click="selectUser(item,index)"
                >
                  <el-badge v-if="item.unread > 0" :value="item.unread">
                    <el-image :src="thumb(item.groupInfo.thumb_avatar)"/>
                  </el-badge>
                  <el-image v-else :src="thumb(item.groupInfo.thumb_avatar)"/>
                </div>
                <!--
                <el-dropdown @command="(e) => handleDropdown(e, item)">
                  <el-dropdown-menu slot="dropdown" style="width: 150px; text-align: center">
                    <el-dropdown-item v-if="item.is_top === 0" command="top">置顶</el-dropdown-item>
                    <el-dropdown-item v-if="item.is_top === 1" command="cancel-top">取消置顶</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
                -->
                <div class="user-info"
                  @click="selectUser(item, index)"
                  @contextmenu.prevent="(e) => { onContextmenu(e, item) }"
                  :style="item.is_main === 1 ? 'width: 160px;' : ''"
                >
                  <div class="hd">
                    <span class="red" style="margin-right: 5px" v-if="item.type === 'group' && item.is_main == 1">[主]</span>
                    <span class="red" style="margin-right: 5px" v-if="item.type === 'group' && item.is_vice == 1">[副]</span>
                    <span class="red" style="margin-right: 5px" v-if="item.type === 'group' && item.is_main != 1 && item.is_vice != 1">[群]</span>
                    <span class="name line1" v-if="item.type === 'private'">{{ item.userInfo.user_id }}</span>
                    <span class="name line1" v-else>{{ item.groupInfo.name }}</span>
                  </div>
                  <div class="bd line1" v-if="item.lastMessage">
                    <template v-if="item.lastMessage.type == 'text'"><div v-html="item.lastMessage.body.text"/></template>
                    <template v-if="item.lastMessage.type == 'image'">[图片]</template>
                    <template v-if="item.lastMessage.type == 'audio'">[音频]</template>
                  </div>
                  <div class="bd line1" v-if="(item.is_main == 1 || item.is_vice == 1) && item.is_mute === 1">
                    <div type="text" style="color: red">禁言中</div>
                  </div>
                  <div class="bd line1" v-if="item.remarks && item.remarks != ''">
                    <template><span style="color: #0e73e8; font-weight: bold">{{item.remarks}}</span></template>
                  </div>
                </div>
                <div class="right-box" v-if="item.type == 'private'">
                  <div style="display: flex; justify-content: right; width: 100%">
                    <div :class="{'in-main': true, active: true}" v-if="item.in_vice_group === 1" @click="handleDropdown('kick-from-vice', item)">副</div>
                    <div :class="{'in-main': true}" v-else @click="handleDropdown('move-in-vice', item)">副</div>

                    <div :class="{'in-main': true, active: true}" v-if="item.in_main_group === 1" @click="handleDropdown('kick-from-main', item)">主</div>
                    <div :class="{'in-main': true}" v-else @click="handleDropdown('move-in-main', item)">主</div>
                  </div>

                  <div v-if="item.userInfo.is_mute === 1" @click="handleDropdown('cancel-mute', item)">
                    <el-icon class="mute active el-icon-turn-off-microphone"></el-icon>
                  </div>
                  <div v-else="item.userInfo.is_mute !== 1" @click="handleDropdown('mute', item)">
                    <el-icon class="mute el-icon-microphone"></el-icon>
                  </div>
                </div>
                <div class="right-box" v-else-if="item.is_main === 0 && item.is_vice === 0">
                  <el-button type="primary" size="mini" v-if="current.conversationId == item.conversationId && !showVirtualUser.includes(item.conversationId) && item.is_main != 1" @click="openVirtualChat(item.conversationId)">群聊</el-button>
                  <el-button type="warning" size="mini" v-if="current.conversationId == item.conversationId && showVirtualUser.includes(item.conversationId) && item.is_main != 1" @click="openVirtualChat(item.conversationId)">关闭</el-button>
                </div>
                <div :class="{'is-top': true, active: item.is_top == 1}" />
              </div>
            </div>
          </vue-scroll>
          <el-empty v-if="conversationList.length == 0" description="暂无会话" status="1"></el-empty>
        </el-tab-pane>
        <el-tab-pane :label="`历史 ` + historyConversationList.length + ``" name="second">
          <vue-scroll style="height: calc(100vh - 125px)" :ops="ops" @handle-scroll="handleScroll" v-if="historyConversationList.length>0">
            <div v-for="(item, index) in historyConversationList" :key="index">
              <div class="chat-item"
                :class="{active: current.conversationId == item.conversationId}"
              >
                <div :class="{avatar: true, withdraw: item.has_withdraw > 0, 'withdraw-virtual': item.withdraw_virtual > 0, 'withdraw-submit': item.withdraw_submit > 0, 'withdraw-freeze': item.withdraw_status == 2}"
                  v-if="item.type === 'private'"
                  @click="selectUser(item, index)"
                  @contextmenu.prevent="(e) => { onContextmenu(e, item) }"
                >
                  <el-badge v-if="item.unread > 0" :value="item.unread">
                    <el-image :src="thumb(item.userInfo.thumb_avatar)"/>
                  </el-badge>
                  <el-image v-else :src="thumb(item.userInfo.thumb_avatar)"/>
                  <div class="status" :class="{off: !item.userInfo.online || item.userInfo.online == 0}"></div>
                </div>
                <div class="avatar"
                  v-else
                  @click="selectUser(item,index)"
                >
                  <el-badge v-if="item.unread > 0" :value="item.unread">
                    <el-image :src="thumb(item.groupInfo.thumb_avatar)"/>
                  </el-badge>
                  <el-image v-else :src="thumb(item.groupInfo.thumb_avatar)"/>
                </div>
                <!--
                <el-dropdown @command="(e) => handleDropdown(e, item)">
                  <el-dropdown-menu slot="dropdown" style="width: 150px; text-align: center">
                    <el-dropdown-item v-if="item.is_top === 0" command="top">置顶</el-dropdown-item>
                    <el-dropdown-item v-if="item.is_top === 1" command="cancel-top">取消置顶</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
                -->
                <div class="user-info"
                  @click="selectUser(item, index)"
                  @contextmenu.prevent="(e) => { onContextmenu(e, item) }"
                  :style="item.is_main === 1 ? 'width: 160px;' : ''"
                >
                  <div class="hd">
                    <span class="red" style="margin-right: 5px" v-if="item.type === 'group' && item.is_main == 1">[主]</span>
                    <span class="red" style="margin-right: 5px" v-if="item.type === 'group' && item.is_main != 1">[群]</span>
                    <span class="name line1" v-if="item.type === 'private'">{{ item.userInfo.user_id }}</span>
                    <span class="name line1" v-else>{{ item.groupInfo.name }}</span>
                  </div>
                  <div class="bd line1" v-if="item.lastMessage">
                    <template v-if="item.lastMessage.type == 'text'"><div v-html="item.lastMessage.body.text"/></template>
                    <template v-if="item.lastMessage.type == 'image'">[图片]</template>
                    <template v-if="item.lastMessage.type == 'audio'">[音频]</template>
                  </div>
                  <div class="bd line1" v-if="item.is_main == 1 && item.is_mute === 1">
                    <div type="text" style="color: red">禁言中</div>
                  </div>
                  <div class="bd line1" v-if="item.remarks && item.remarks != ''">
                    <template><span style="color: #0e73e8; font-weight: bold">{{item.remarks}}</span></template>
                  </div>
                </div>
                <div class="right-box" v-if="item.type == 'private'">
                  <div :class="{'in-main': true, active: true}" v-if="item.in_main_group === 1" @click="handleDropdown('kick-from-main', item)">主</div>
                  <div :class="{'in-main': true}" v-else @click="handleDropdown('move-in-main', item)">主</div>

                  <div v-if="item.userInfo.is_mute === 1" @click="handleDropdown('cancel-mute', item)">
                    <el-icon class="mute active el-icon-turn-off-microphone"></el-icon>
                  </div>
                  <div v-else="item.userInfo.is_mute !== 1" @click="handleDropdown('mute', item)">
                    <el-icon class="mute el-icon-microphone"></el-icon>
                  </div>
                </div>
                <div class="right-box" v-else-if="item.is_main === 0">
                  <el-button type="primary" size="mini" v-if="current.conversationId == item.conversationId && !showVirtualUser.includes(item.conversationId) && item.is_main != 1" @click="openVirtualChat(item.conversationId)">群聊</el-button>
                  <el-button type="warning" size="mini" v-if="current.conversationId == item.conversationId && showVirtualUser.includes(item.conversationId) && item.is_main != 1" @click="openVirtualChat(item.conversationId)">关闭</el-button>
                </div>
                <div :class="{'is-top': true, active: item.is_top == 1}" />
              </div>
            </div>
          </vue-scroll>
          <el-empty v-if="conversationList.length == 0" description="暂无会话" status="1"></el-empty>
        </el-tab-pane>
        <el-tab-pane label="屏蔽" name="three">
          <vue-scroll style="height: calc(100vh - 65px)" :ops="ops" @handle-scroll="handleScroll" v-if="hideConversationList.length>0">
            <div v-for="(item, index) in hideConversationList" :key="index">
              <div class="chat-item"
                :class="{active: current.conversationId == item.conversationId}"
              >
                <div :class="{avatar: true, withdraw: item.has_withdraw > 0, 'withdraw-submit': item.withdraw_submit > 0, 'withdraw-freeze': item.withdraw_status == 2}"
                  v-if="item.type === 'private'"
                  @click="selectUser(item,index)"
                  @contextmenu.prevent="(e) => { onContextmenu(e, item) }"
                >
                  <el-badge v-if="item.unread > 0" :value="item.unread">
                    <el-image :src="thumb(item.userInfo.thumb_avatar)"/>
                  </el-badge>
                  <el-image v-else :src="thumb(item.userInfo.thumb_avatar)"/>
                  <div class="status" :class="{off: !item.userInfo.online || item.userInfo.online == 0}"></div>
                </div>
                <div class="avatar"
                  v-else
                  @click="selectUser(item,index)"
                >
                  <el-badge v-if="item.unread > 0" :value="item.unread">
                    <el-image :src="thumb(item.groupInfo.thumb_avatar)"/>
                  </el-badge>
                  <el-image v-else :src="thumb(item.groupInfo.thumb_avatar)"/>
                </div>
                <div class="user-info"
                  @click="selectUser(item,index)"
                  @contextmenu.prevent="(e) => { onContextmenu(e, item) }"
                >
                  <div class="hd">
                    <span class="name line1">{{ item.type === 'private' ? item.userInfo.user_id : item.groupInfo.name }}</span>
                    <span class="name red" v-if="item.type === 'group'"> [群]</span>
                  </div>
                  <div class="bd line1" v-if="item.lastMessage">
                    <template v-if="item.lastMessage.type == 'text'">{{item.lastMessage.body.text}}</template>
                    <template v-if="item.lastMessage.type == 'image'">[图片]</template>
                  </div>
                </div>
                <div class="right-box" v-if="item.type == 'private'">
                  <div>{{current.conversationId == item.conversationId ? 'a' : 'b'}}ee</div>
                  <div :class="{'in-main': true, active: true}" v-if="item.in_main_group === 1" @click="handleDropdown('kick-from-main', item)">58</div>
                  <div :class="{'in-main': true}" v-else @click="handleDropdown('move-in-main', item)">58</div>

                  <div v-if="item.userInfo.is_mute === 1" @click="handleDropdown('cancel-mute', item)">
                    <el-icon class="mute active el-icon-turn-off-microphone"></el-icon>
                  </div>
                  <div v-else="item.userInfo.is_mute !== 1" @click="handleDropdown('mute', item)">
                    <el-icon class="mute el-icon-microphone"></el-icon>
                  </div>
                  <div class="num" v-if="item.unread > 0">
                    <el-badge :value="item.unread">
                      <a href="#" class="demo-badge"></a>
                    </el-badge>
                  </div>
                </div>
                <div class="right-box" v-else>
                  <el-button type="primary" size="mini" v-if="current.conversationId == item.conversationId && !showVirtualUser.includes(item.conversationId) && item.is_main != 1" @click="openVirtualChat(item.conversationId)">群聊</el-button>
                  <el-button type="warning" size="mini" v-if="current.conversationId == item.conversationId && showVirtualUser.includes(item.conversationId) && item.is_main != 1" @click="openVirtualChat(item.conversationId)">关闭</el-button>
                </div>
                <div :class="{'is-top': true, active: item.is_top == 1}" />
              </div>
            </div>
          </vue-scroll>
          <el-empty v-if="hideConversationList.length == 0" description="暂无会话" status="1"></el-empty>
        </el-tab-pane>
      </el-tabs>
      <div v-if="showBot" style="background: #e8e8e8; position: absolute; bottom: 0; width: 100%">
        <div style="padding: 10px;display: flex; justify-content: space-between;">
          <el-input
            v-model="gift"
            placeholder="ID+空格+数额+空格+备注"
            suffix-icon="el-icon-finished"
            @change="handleSendGift"
          />
        </div>
      </div>
    </el-aside>
    <el-main style="overflow: hidden !important;">
      <el-empty v-if="!current" description="未开始对话"></el-empty>
      <div :class="{'msg-window': true, 'hide': !current}">
        <msg-window ref="msgWindow" :show-virtual-user="showVirtualUser" @hide-virtual-user="(res) => { openVirtualChat(res) }" @newConversation="newConversation" @changeField="handleChangeField"></msg-window>
      </div>
    </el-main>
    <dialog-search ref="dialogSearch" @new-conversation="newConversation"/>
  </el-container>
</template>
<script>

import {
  mapState
} from 'vuex'
import { dateTimeFormat, thumb } from '@/utils/utils'

import MsgWindow from "@/views/chat/components/msg-window";
import DialogSearch from "@/views/chat/components/dialog-search";
import {
  YeIMUniSDK
} from '@/utils/wzJun1-YeIM-Uni-SDK/js_sdk/yeim-uni-sdk.min';

import { ipcApiRoute, requestHttp } from "@/api/main";
export default {
  name: 'ChatList',
  components: {
    MsgWindow, DialogSearch
  },
  data() {
    return {
      keyword: '',
      gift: '',
      menu:{},
      current: false,
      currentConversationId: '',
      activeTabName: 'first',
      keys: [],
      showVirtualUser: [],
      totalUnread: 0,
      oldConversationList: [],
      ops: {
        vuescroll: {
          mode: 'native',
          enable: false,
          tips: {
            deactive: 'Push to Load',
            active: 'Release to Load',
            start: 'Loading...',
            beforeDeactive: 'Load Successfully!'
          },
          auto: false,
          autoLoadDistance: 0,
          pullRefresh: {
            enable: false
          },
          pushLoad: {
            enable: true,
            auto: true,
            autoLoadDistance: 10
          }
        },
        bar: {
          background: '#393232',
          opacity: '.5',
          size: '5px'
        }
      },
      isLoad: true,
      records: []
    };
  },
  computed: {
    ...mapState({
      user: state => state.yeim.user,
      connected: state => state.yeim.connected,
      showBot: state => state.showBot,
      subAccountList: state => state.subAccountList
    }),
    conversationList() {
      if (!this.current && this.$store.state.yeim.conversationList.length > 0 && this.currentConversationId == '') {
        this.menuHandle(this.$store.state.yeim.conversationList[0])
      }
      const that = this
      that.totalUnread = 0
      this.$store.state.yeim.conversationList.forEach((item) => {
        if (item.is_main != 1 && item.is_vice != 1) {
          that.totalUnread += item.unread
        }
      })

      try {
        if (that.totalUnread > 99) {
          that.totalUnread = 99
        }
        this.$ipc.invoke(ipcApiRoute.showUnread, that.totalUnread);
      } catch (err) {

      }

      return this.$store.state.yeim.conversationList.filter((item) => {
        if (that.keyword !== '' && (item.title && item.title.toUpperCase().indexOf(that.keyword.toUpperCase()) === -1) && item.login_info.toUpperCase().indexOf(that.keyword.toUpperCase()) === -1 && item.remarks.toUpperCase().indexOf(that.keyword.toUpperCase()) === -1) {
          return false;
        }
        if (item.is_hide == 1) {
          return false;
        }
        return true;
      })
    },
    hideConversationList() {
      const that = this
      return this.$store.state.yeim.conversationList.filter((item) => {
        if (that.keyword !== '' && item.title && item.title.toUpperCase().indexOf(that.keyword.toUpperCase()) === -1) {
          return false;
        }
        if (item.is_hide == 0) {
          return false;
        }
        return true;
      })
    },
    historyConversationList() {
      const that = this
      return this.oldConversationList.filter((item) => {
        if (that.keyword !== '' && (item.title && item.title.toUpperCase().indexOf(that.keyword.toUpperCase()) === -1) && item.login_info.toUpperCase().indexOf(that.keyword.toUpperCase()) === -1 && item.remarks.toUpperCase().indexOf(that.keyword.toUpperCase()) === -1) {
          return false;
        }
        return true;
      })
    }
  },
  created () {
    // this.$store.state.yeim.conversationList = []
    if (!this.$store.state.yeim.yeimLogin) {
      this.$store.dispatch('yeim/reset')
    }
    if (this.user.username == '' || this.user.remark_nickname == '') {
      this.$router.push({ name: 'Setting', params: {active: 'person'}})
      return;
    }
    if (this.$route.params && this.$route.params.type && this.$route.params.toId) {
      this.currentConversationId = this.$route.params.toId
      this.newConversationById(this.$route.params.type, this.$route.params.toId)
    }
    this.getHistoryConversation()
  },
  methods: {
    dateTimeFormat,
    thumb,
    onSearch() {

    },
    handleTabClick () {
      // this.keyword = ''
      if (this.activeTabName == 'second') {
        this.getHistoryConversation()
      }
    },
    handleChangeField(data) {
      this.setConversationField(data.conversationId, data.field, data.value)
    },
    getHistoryConversation() {
      const that = this
      requestHttp('conversation/list', {'is_old': 1}).then((res) => {
        console.log(res)
        that.oldConversationList = res.data.data
      }).catch((err) => {
        console.log(err)
      })
    },
    menuHandle (item) {
      const that = this
      if (!item) {
        return
      }
      this.current = item
      if (that.$refs.msgWindow) {
        that.$refs.msgWindow.initGG(item)
      } else {
        setTimeout(function () {
          that.$refs.msgWindow.initGG(item)
        }, 1000)
      }
    },
    menuClick(e) {
      this.current = e.key;
    },

    handleShowSearch(type) {
      this.$refs.dialogSearch.toggle(type)
    },

    handleScroll() {

    },
    selectUser(item, idx) {
      const that = this
      if (this.current.conversation_id == item.conversation_id) {
        return
      }
      this.current = item
      if (that.$refs.msgWindow) {
        that.$refs.msgWindow.initGG(item)
      } else {
        setTimeout(function () {
          that.$refs.msgWindow.initGG(item)
        }, 1000)
      }
    },
    setConversationField(conversationId, field, val) {
      this.$store.state.yeim.conversationList.forEach((item) => {
        if (item.conversationId == conversationId) {
          item[field] = val
          if (item['userInfo']) {
            item['userInfo'][field] = val
          }
        }
      })
    },
    onContextmenu(event, item) {
      const that = this
      let items = []
      if (item.is_top != 1) {
        items.push({
          label: "置顶",
          onClick: () => {
            that.handleDropdown('top', item)
          }
        })
      } else {
        items.push({
          label: "取消置顶",
          onClick: () => {
            that.handleDropdown('cancel-top', item)
          }
        })
      }
      if (item.is_main != 1) {
        if (item.is_hide != 1) {
          items.push({
            label: "屏蔽",
            onClick: () => {
              that.handleDropdown('hide', item)
            }
          })
        } else {
          items.push({
            label: "取消屏蔽",
            onClick: () => {
              that.handleDropdown('cancel-hide', item)
            }
          })
        }
        if (item.is_example != 1) {
          items.push({
            label: "分享",
            onClick: () => {
              that.handleDropdown('share', item)
            }
          })
        } else {
          items.push({
            label: "取消分享",
            onClick: () => {
              that.handleDropdown('cancel-share', item)
            }
          })
        }
      }
      if (item.type == 'group') {
        if (item.is_main != 1) {
          items.push({
            label: "解散群聊",
            onClick: () => {
              that.handleDropdown('dissolve', item)
            }
          })
        }
        // items.push({
        //   label: '退出群聊',
        //   onClick: () => {
        //     that.handleDropdown('exit-group', item)
        //   }
        // })
      } else {
        items.push({
          label: "删除好友",
          onClick: () => {
            that.handleDropdown('delete', item)
          }
        })
      }
      this.$contextmenu({
        items: items,
        event, // 鼠标事件信息
        customClass: "custom-class", // 自定义菜单 class
        zIndex: 3, // 菜单样式 z-index
        minWidth: 80 // 主菜单最小宽度
      });
      return false;
    },
    handleDropdown(e, item) {
      console.log(e, item)
      const that = this
      if (e == 'move-in-main') {
        this.$confirm('确定移入主群？',  '提示').then(function () {
          that.setConversationField(item.conversationId, 'in_main_group', 1)
          requestHttp('group/move_in_main', {
            'user_id': item.friend_user_id
          }).then((res) => {
            if (res.code == 200) {
              that.$message.success({message: res.message, offset: 100})
              YeIMUniSDK.getInstance().reload();
            } else {
              that.setConversationField(item.conversationId, 'in_main_group', 0)
              that.$message.error({message: res.message, offset: 100})
            }
          })
        })
      }
      if (e == 'kick-from-main') {
        this.$confirm('确定踢出主群？',  '提示').then(function () {
          that.setConversationField(item.conversationId, 'in_main_group', 0)
          requestHttp('group/kick_from_main', {
            'user_id': item.friend_user_id
          }).then((res) => {
            if (res.code == 200) {
              that.$message.success({message: res.message, offset: 100})
              YeIMUniSDK.getInstance().reload();
            } else {
              that.setConversationField(item.conversationId, 'in_main_group', 1)
              that.$message.error({message: res.message, offset: 100})
            }
          })
        })
      }
      if (e == 'move-in-vice') {
        this.$confirm('确定移入副群？',  '提示').then(function () {
          that.setConversationField(item.conversationId, 'in_vice_group', 1)
          requestHttp('group/move_in_vice', {
            'user_id': item.friend_user_id
          }).then((res) => {
            if (res.code == 200) {
              that.$message.success({message: res.message, offset: 100})
              YeIMUniSDK.getInstance().reload();
            } else {
              that.setConversationField(item.conversationId, 'in_vice_group', 0)
              that.$message.error({message: res.message, offset: 100})
            }
          })
        })
      }
      if (e == 'kick-from-vice') {
        this.$confirm('确定踢出副群？',  '提示').then(function () {
          that.setConversationField(item.conversationId, 'in_vice_group', 0)
          requestHttp('group/kick_from_vice', {
            'user_id': item.friend_user_id
          }).then((res) => {
            if (res.code == 200) {
              that.$message.success({message: res.message, offset: 100})
              YeIMUniSDK.getInstance().reload();
            } else {
              that.setConversationField(item.conversationId, 'in_vice_group', 1)
              that.$message.error({message: res.message, offset: 100})
            }
          })
        })
      }
      if (e == 'mute') {
        this.$confirm('禁言后，将无法在主群发言，确定吗？',  '提示').then(function () {
          that.setConversationField(item.conversationId, 'is_mute', 1)
          requestHttp('agent/switch_mute', {
            'user_id': item.friend_user_id,
            'is_mute': 1
          }).then((res) => {
            if (res.code == 200) {
              that.setConversationField(item.conversationId, 'is_mute', 1)
              that.$message.success({message: res.message, offset: 100})
              YeIMUniSDK.getInstance().reload();
            } else {
              that.setConversationField(item.conversationId, 'is_mute', 0)
              that.$message.error({message: res.message, offset: 100})
            }
          })
        })
      }
      if (e == 'cancel-mute') {
        this.$confirm('确定取消禁言状态，用户将可以发表？',  '提示').then(function () {
          that.setConversationField(item.conversationId, 'is_mute', 0)
          requestHttp('agent/switch_mute', {
            'user_id': item.friend_user_id,
            'is_mute': 0
          }).then((res) => {
            if (res.code == 200) {
              that.setConversationField(item.conversationId, 'is_mute', 0)
              that.$message.success({message: res.message, offset: 100})
              YeIMUniSDK.getInstance().reload();
            } else {
              that.setConversationField(item.conversationId, 'is_mute', 1)
              that.$message.error({message: res.message, offset: 100})
            }
          })
        })
      }
      if (e == 'top' || e == 'cancel-top') {
        requestHttp('conversation/switch_top', {
          'conversation_id': item.conversationId,
          'is_top': e == 'top' ? 1 : 0
        }).then((res) => {
          if (res.code == 200) {
            YeIMUniSDK.getInstance().reload();
          } else {
            that.$message.error({message: res.message, offset: 100})
          }
        })
      }
      if (e == 'hide' || e == 'cancel-hide') {
        requestHttp('conversation/switch_hide', {
          'conversation_id': item.conversationId,
          'is_hide': e == 'hide' ? 1 : 0
        }).then((res) => {
          if (res.code == 200) {
            YeIMUniSDK.getInstance().reload();
          } else {
            that.$message.error({message: res.message, offset: 100})
          }
        })
      }
      if (e == 'delete') {
        this.$confirm('确定删除 ' + item.title,  '提示').then(function () {
          YeIMUniSDK.getInstance().deleteFriend({
            members: [item.friend_user_id],
            success: (res) => {
              YeIMUniSDK.getInstance().reload();
            },
            fail: (res) => {
              that.$message.error({message: res.message, offset: 100})
            }
          })
        })
      }
      if (e == 'exit-group') {
        this.$confirm('确定退出群聊 ' + item.title,  '提示').then(function () {
          YeIMUniSDK.getInstance().deleteConversation(item.conversationId)
        })
      }
      if (e == 'dissolve') {
        this.$confirm('确定解散群组 ' + item.title,  '提示').then(function () {
          YeIMUniSDK.getInstance().dissolveGroup({
            groupId: item.conversationId,
            success: (res) => {
              console.log(res)
              YeIMUniSDK.getInstance().reload()
            },
            fail: (res) => {
              console.log(res)
            }
          })
        })
      }
      if (e == 'share') {
        this.$prompt('请输入分享标题', '操作', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputValue: '',
          inputPlaceholder: '分享的亮点等',
        }).then(({ value }) => {
          that.setConversationField(item.conversationId, 'is_example', 1)
          requestHttp('agent/do_example_share', {
            'conversation_id': item.conversationId,
            'example_title': value
          }).then((res) => {
            if (res.code == 200) {
              that.$message.success(res.message)
            } else {
              that.setConversationField(item.conversationId, 'is_example', 0)
              that.$message.error(res.message)
            }
          })
        })
      }
      if (e == 'cancel-share') {
        this.$confirm('确定取消分享 ' + item.title,  '提示').then(function () {
          that.setConversationField(item.conversationId, 'is_example', 1)
          requestHttp('agent/cancel_example_share', {
            'conversation_id': item.conversationId
          }).then((res) => {
            if (res.code == 200) {
              that.$message.success(res.message)
            } else {
              that.setConversationField(item.conversationId, 'is_example', 1)
              that.$message.error(res.message)
            }
          })
        })
      }
    },
    handleContextMenu(item, idx) {
      const that = this
      this.current = item
      if (that.$refs.msgWindow) {
        that.$refs.msgWindow.initGG(item)
      } else {
        setTimeout(function () {
          that.$refs.msgWindow.initGG(item)
        }, 1000)
      }
    },
    scrollTop() {

    },
    scrollHandler() {

    },
    openVirtualChat(conversationId) {
      if (this.showVirtualUser.includes(conversationId)) {
        const idx = this.showVirtualUser.findIndex(item => item === conversationId)
        if (idx !== -1) {
          this.showVirtualUser.splice(idx, 1)
        }
      } else {
        this.showVirtualUser.push(conversationId)
      }
    },
    newConversation(conversation) {
      this.menuHandle(conversation)
    },
    newConversationById(type, toId) {
      const idx1 = this.conversationList.findIndex(item => {
        return item.conversationId == (this.user.userId +'@@'+ toId)
      })
      if (idx1 !== -1) {
        this.menuHandle(this.conversationList[idx1])
        return
      }
      const idx2 = this.conversationList.findIndex(item => {
        return item.conversationId == (toId + '@@' + this.user.userId)
      })
      if (idx2 !== -1) {
        this.menuHandle(this.conversationList[idx2])
        return
      }

      const that = this
      requestHttp('agent/new_conversation', {
        'type': type,
        'to_id': toId
      }).then((res) => {
        if (res.code == 200) {
          // 重新加载好友等数据
          that.$store.state.conversationList = []
          that.$store.state.friendList = []
          that.$store.state.friendApplyUnread = []
          that.$store.state.friendApplyList = []

          YeIMUniSDK.getInstance().reload();
          this.menuHandle(res.data)
        } else {
          that.$message.error({ message: res.message, offset: 100 })
        }
      })
    },
    handleSendGift() {
      const that = this
      requestHttp('agent/bot_send_gift', {
        'msg': that.gift
      }).then((res) => {
        if (res.code == 200) {
          that.$notify({
            title: '',
            message: res.message,
            type: 'success',
            position: 'bottom-left',
            offset: 50,
            duration: 5000
          });
          that.gift = ''
        } else {
          that.$notify({
            title: '',
            message: res.message,
            type: 'error',
            position: 'bottom-left',
            offset: 50,
            duration: 5000
          });
          that.gift = ''
        }
      })
    }
  }
};
</script>
<style lang="less" scoped>
#app-menu {
  height: 100%;
  text-align: center;
  .layout-sider {
    border-top: 1px solid #e8e8e8;
    border-right: 1px solid #e8e8e8;
    background-color: #FAFAFA;
    overflow: auto;
    position: relative;
  }
  .el-tabs {
    .el-tabs__header {
      margin: 0;
    }
  }

  .scroll-box {
    flex: 1;
    height: 500px;
    overflow: hidden;
  }

  .chat-item {
    display: flex;
    align-items: center;
    justify-content: left;
    padding: 0px 5px;
    height: 64px;
    box-sizing: border-box;
    border-left: 3px solid transparent;
    cursor: pointer;
    border-bottom: 1px solid #eee;
    position: relative;

    &.active {
      background: rgba(24, 144, 239, 0.3);
      border-left: 3px solid #1890FF;
    }

    .avatar {
      position: relative;
      width: 40px;
      height: 40px;
      border-radius: 4px;
      &.withdraw {
        width: 37px;
        height: 37px;
        border: 3px solid green;
      }
      &.withdraw-virtual {
        width: 37px;
        height: 37px;
        border: 3px solid #ff00ff;
      }
      &.withdraw-submit {
        width: 37px;
        height: 37px;
        border: 3px solid orange;
      }
      &.withdraw-freeze {
        width: 37px;
        height: 37px;
        border: 3px solid red;
      }

      .el-image {
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 4px;
        img {
          width: 40px;
          height: 40px;
        }
      }

      .status {
        position: absolute;
        right: 3px;
        bottom: 0;
        width: 8px;
        height: 8px;
        background: #48D452;
        border: 1px solid #fff;
        border-radius: 50%;

        &.off {
          background: #999999;
        }
      }
    }

    .user-info {
      width: 150px;
      margin-left: 5px;
      font-size: 16px;
      text-align: left;

      .hd {
        display: flex;
        align-items: center;
        color: rgba(0, 0, 0, 0.65);
        font-size: 12px;

        .name {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .red {
          color: red;
        }

        .label {
          margin-left: 5px;
          color: #3875EA;
          font-size: 12px;
          background: #D8E5FF;
          border-radius: 2px;
          padding: 1px 5px;

          &.H5 {
            background: #FAF1D0;
            color: #DC9A04;
          }

          &.wechat {
            background: rgba(64, 194, 73, 0.16);
            color: #40C249;
          }

          &.pc {
            background: rgba(100, 64, 194, 0.16);
            color: #6440C2;
          }
        }
      }

      .bd {
        font-size: 12px;
        color: #8E959E;
        text-align: left;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        height: 18px;
      }
    }
    .right-box {
      width: 60px;
      height: 64px;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction:column;
      top: 0;
      right: 5px;
      .time {
        color: #ccc;
      }
      .in-main {
        border-radius: 50%;
        //margin-left: 40px;
        width: 20px;
        height: 20px;
        line-height: 20px;
        border: 1px solid #ccc;
        background: #ccc;
        color: #000;
        font-size: 12px;
        &.active {
          background: #0e73e8;
          color: #fff;
        }
      }
      .mute {
        margin-left: 40px;
        margin-top: 5px;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        line-height: 20px;
        border: 1px solid #0e73e8;
        background: #0e73e8;
        color: #fff;
        &.active {
          border: 1px solid red;
          background: red;
          color: #fff;
        }
      }
    }
    .is-top {
      position: absolute;
      top: 0px;
      right: 0px;
      height: 10px;
      width: 10px;
      clip-path: polygon(0 0, 100% 100%,100% 0);
      display: none;
      &.active {
        background-color: #000;
        display: block;
      }
    }
  }
  .el-main {
    padding: 0px !important;
  }
  .msg-window {
    .hide {
      display: none;
    }
  }
}

</style>
